.app {
  min-height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  display: flex;
  flex-direction: column;
}

.header {
  padding: 4rem 2rem;
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
}

.header h1 {
  font-size: 4rem;
  margin: 0;
  background: linear-gradient(45deg, #2196f3, #00bcd4);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.2rem;
  color: #666;
}

.companies-grid {
  padding: 2rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.company-card {
  background: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.company-card:hover {
  transform: translateY(-5px);
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;
}

.cta-button {
  display: inline-block;
  padding: 0.8rem 1.5rem;
  background: #2196f3;
  color: white;
  text-decoration: none;
  border-radius: 25px;
  transition: all 0.3s ease;
  border: none;
  cursor: pointer;
  font-size: 1rem;
}

.cta-button:hover {
  background: #1976d2;
}

.cta-button.disabled {
  background: #ccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.cta-button.disabled:hover {
  background: #ccc;
  transform: none;
}

.version-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
  margin: 1rem 0;
}

.old-version {
  color: #666;
  text-decoration: line-through;
  opacity: 0.7;
}

.version-arrow {
  color: #2196f3;
  font-size: 1.2rem;
  animation: pulse 2s infinite;
}

.new-version {
  color: #2196f3;
  font-weight: 600;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.7;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.7;
  }
}

.info-section {
  padding: 4rem 2rem;
  position: relative;
  z-index: 1;
}

.info-container {
  max-width: 1200px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.95);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.info-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.info-content h2 {
  color: #2196f3;
  margin: 0;
  font-size: 2rem;
}

.author-info {
  color: #666;
  margin: 0;
  font-size: 1.1rem;
}

.author-link {
  color: #2196f3;
  text-decoration: none;
  font-weight: 600;
  transition: color 0.3s ease;
}

.author-link:hover {
  color: #1976d2;
}

.footer {
  background: rgba(33, 150, 243, 0.1);
  padding: 3rem 2rem 1rem 2rem;
  margin-top: auto;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  text-align: center;
}

.footer-info h3 {
  color: #2196f3;
  margin-bottom: 1rem;
}

.footer-info p {
  color: #666;
}

.footer-links {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.footer-links a {
  color: #2196f3;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #1976d2;
}

.footer-copyright {
  grid-column: 1 / -1;
  color: #666;
  margin-top: 2rem;
  text-align: center;
}

@media (max-width: 768px) {
  .footer-content {
    grid-template-columns: 1fr;
  }

  .footer-links {
    align-items: center;
  }
}
